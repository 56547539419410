
<template>
  <router-view />
</template>
<style>
/* Modal from bootstrap */
.modal {
  --bs-modal-zindex: 1055;
  --bs-modal-width: 500px;
  --bs-modal-padding: 1rem;
  --bs-modal-margin: 0.5rem;
  --bs-modal-color: ;
  --bs-modal-bg: #fff;
  --bs-modal-border-color: var(--bs-border-color-translucent);
  --bs-modal-border-width: 1px;
  --bs-modal-border-radius: 0.3rem;
  --bs-modal-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  --bs-modal-inner-border-radius: calc(0.5rem - 1px);
  --bs-modal-header-padding-x: 1rem;
  --bs-modal-header-padding-y: 1rem;
  --bs-modal-header-padding: .5rem 1rem;
  --bs-modal-header-border-color: var(--bs-border-color);
  --bs-modal-header-border-width: 1px;
  --bs-modal-title-line-height: 1.5;
  --bs-modal-footer-gap: 0.5rem;
  --bs-modal-footer-bg: ;
  --bs-modal-footer-border-color: var(--bs-border-color);
  --bs-modal-footer-border-width: 1px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-modal-zindex);
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: var(--bs-modal-margin);
  pointer-events: none;
}

.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}

@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}

.modal.show .modal-dialog {
  transform: none;
}

.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  height: calc(100% - var(--bs-modal-margin) * 2);
}

.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}

.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - var(--bs-modal-margin) * 2);
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  color: var(--bs-modal-color);
  pointer-events: auto;
  background-color: var(--bs-modal-bg);
  background-clip: padding-box;
  border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
  border-radius: var(--bs-modal-border-radius);
  outline: 0;
}

.modal-backdrop {
  --bs-backdrop-zindex: 1050;
  --bs-backdrop-bg: #000;
  --bs-backdrop-opacity: 0.5;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-backdrop-zindex);
  width: 100vw;
  height: 100vh;
  background-color: var(--bs-backdrop-bg);
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: var(--bs-backdrop-opacity);
}

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-modal-header-padding);
  border-bottom: var(--bs-modal-header-border-width) solid var(--bs-modal-header-border-color);
  border-top-left-radius: var(--bs-modal-inner-border-radius);
  border-top-right-radius: var(--bs-modal-inner-border-radius);
}

.modal-header .btn-close {
  padding: calc(var(--bs-modal-header-padding-y) * 0.5) calc(var(--bs-modal-header-padding-x) * 0.5);
  margin: calc(-0.5 * var(--bs-modal-header-padding-y)) calc(-0.5 * var(--bs-modal-header-padding-x)) calc(-0.5 * var(--bs-modal-header-padding-y)) auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: var(--bs-modal-title-line-height);
  font-weight: bold;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: var(--bs-modal-padding);
}

.modal-footer {
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: calc(var(--bs-modal-padding) - var(--bs-modal-footer-gap) * 0.5);
  background-color: var(--bs-modal-footer-bg);
  border-top: var(--bs-modal-footer-border-width) solid var(--bs-modal-footer-border-color);
  border-bottom-right-radius: var(--bs-modal-inner-border-radius);
  border-bottom-left-radius: var(--bs-modal-inner-border-radius);
}

.modal-footer>* {
  margin: calc(var(--bs-modal-footer-gap) * 0.5);
}

@media (min-width: 576px) {
  .modal {
    --bs-modal-margin: 1.75rem;
    --bs-modal-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  }

  .modal-dialog {
    max-width: var(--bs-modal-width);
    margin-right: auto;
    margin-left: auto;
  }

  .modal-sm {
    --bs-modal-width: 300px;
  }
}

@media (min-width: 992px) {

  .modal-lg,
  .modal-xl {
    --bs-modal-width: 800px;
  }
}

@media (min-width: 1200px) {
  .modal-xl {
    --bs-modal-width: 1140px;
  }
}

.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}

.modal-fullscreen .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}

.modal-fullscreen .modal-header,
.modal-fullscreen .modal-footer {
  border-radius: 0;
}

.modal-fullscreen .modal-body {
  overflow-y: auto;
}

@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-sm-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .modal-fullscreen-sm-down .modal-header,
  .modal-fullscreen-sm-down .modal-footer {
    border-radius: 0;
  }

  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }
}

@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-md-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .modal-fullscreen-md-down .modal-header,
  .modal-fullscreen-md-down .modal-footer {
    border-radius: 0;
  }

  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }
}

@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-lg-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .modal-fullscreen-lg-down .modal-header,
  .modal-fullscreen-lg-down .modal-footer {
    border-radius: 0;
  }

  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }
}

@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-xl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .modal-fullscreen-xl-down .modal-header,
  .modal-fullscreen-xl-down .modal-footer {
    border-radius: 0;
  }

  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }
}

@media (max-width: 1399.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-xxl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .modal-fullscreen-xxl-down .modal-header,
  .modal-fullscreen-xxl-down .modal-footer {
    border-radius: 0;
  }

  .modal-fullscreen-xxl-down .modal-body {
    overflow-y: auto;
  }
}

/* Modal from bootstrap */

.show {
  display: block;
}

.hide {
  display: none;
}

.icon-ht {
  height: 18px;
  width: 18px;
}

.icon-m {
  height: 18px;
  width: 18px;
}

/* For transition */
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}


.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.bounce-enter-active {
  animation: bounce-in 0.5s;
}

.bounce-leave-active {
  animation: bounce-in 0.5s reverse;
}

@keyframes bounce-in {
  0% {
    transform: scale(0);
  }

  50% {
    transform: scale(1);
  }

  100% {
    transform: scale(1);
  }
}

/* For transition */

.prof-title {
  font-weight: bold;
  color: #444
}

.prof-text {
  color: #777
}

.prof-row {
  margin-bottom: 7px;
}

.prof-hr {
  margin: -10px 0px -3px 0px;
}

.c-royal-blue {
  color: #002366 !important;
}

.c-maroon {
  color: #800000 !important;
}

.c-d-orange {
  color: #8B4000 !important;
}

.c-indigo {
  color: #4B0082 !important;
}

.formkit-label,
.formkit-input,
.formkit-message,
.formkit-legend {
  font-family: 'Lexend', sans-serif !important;
}

.formkit-label {
  font-weight: 500 !important;
  font-size: .75em !important;
  margin-bottom: -4px !important;
}

.formkit-input {
  font-weight: normal !important;
  padding: .65em 1em !important;
  font-size: 12px !important;
  border-radius: 15px !important;
}

.formkit-wrapper {
  max-width: 100% !important;
}

[data-type="radio"] fieldset.formkit-fieldset {
  border-radius: 20px !important;
}

.formkit-message {

  font-weight: normal !important;
  font-size: 12px !important;
  padding: 0px 0px 8px 8px !important;
  float: left !important;

}

.formkit-legend {
  font-weight: normal !important;
  font-size: 14px !important;
  margin-bottom: 10px !important;
  font-weight: 500 !important;
}

button.formkit-input {
  font-size: .8em !important;
  padding: .2em .8em .23em .8em !important;
  background-color: #0080C7 !important;
  border-radius: 0px !important;
}

.formkit-option .formkit-label {
  font-weight: normal !important;
}


/* spinner */

.loader {
  border: 4px solid #f3f3f3;
  /* Light grey */
  border-top: 4px solid #3498db;
  /* Blue */
  border-right: 4px solid #3498db;
  /* Blue */
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin .9s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}


/* spinner */

.main-tit {
  color: #FF9933;
}

.main-tit:hover {
  color: #FF9933;
}

.card {
  border: 1px solid #e7e1e1;
}

.vgt-wrap__footer {
  padding: .3em 1em .2em 1em !important;
}

.footer__navigation {
  font-size: .7rem !important;
}

.vgt-table td .btn {
  padding: 1px 6px !important;
  font-size: .9em;
}

.vgt-table td {
  padding: 6px 7px !important;
  font-size: .77em;
  line-height: 1em;
  vertical-align: middle !important;
}

.vgt-table th {
  font-size: .8em;
  line-height: .8em;
  vertical-align: middle !important;
}

.vgt-wrap__footer {
  padding: .28em 2em !important;
}

.vgt-wrap__footer * {
  font-size: .92em !important;
}

.vgt-table {
  letter-spacing: -.3px;
}

.footer__row-count__label,
.footer__row-count__select {
  font-size: .8em !important;
}

.footer__navigation>button {
  font-size: .8em !important;
}


.formkit-inner {
  border-radius: 0 !important;
}


.view-info-card p,
.view-info-card b {
  font-size: 14px;
  letter-spacing: -0.5px;
  padding-inline-start: .9em;
  padding-inline-end: .7em;
}

.view-info-card hr {
  margin-inline-start: .9em !important;
  margin-inline-end: .9em !important;
}


/* for mobile */
@media (max-width: 576px) {
  .content {
    padding-left: 0;
    padding-right: 0;
  }

  .cont-title {
    padding-left: 1em;
    padding-right: 1em
  }

  .dash-card {
    margin-left: 18px;
    margin-right: 18px;
  }
}

.btn-tiny {
  max-height: 15px;
  font-size: 11px !important;
  padding: 0px 7px 17px 7px !important;
  border-radius: 1px !important;
  text-align: center;
}

input[type="time"] {
  max-height: 25px !important;
}

.subitm .left i {
  margin-left: 15px !important;
}

.custom-fk-outer {
  padding: 0px !important;
}

/* .custom-fk-inner{
    padding: 0px !important;
} */

input:-webkit-autofill {
  -webkit-background-clip: text;
}

/* 
.formkit-options {
   display: flex;
}
.formkit-option {
   padding-right: 20px !important;
} */

.custom-fk-radio {
  display: flex;
}

.custom-fk-r-option {
  padding-right: 10px !important;
}

.custom-fk-r-fieldset {
  border: none !important;
  padding: 3px !important;
}

.custom-fk-sel-icn {
  display: none !important;
}


.modal-backdrop.show {
  opacity: 0;
}

.modal-backdrop {
  --bs-backdrop-zindex: 0;
}

.prod-ht {
  height: 40px;
}

.orange-fnt {
  color: #8B4000;
}

.blue-fnt {
  color: #000000
}

.time-modal {
  border: 2px solid #d6d4d4;
  background: #ffffff;
}
.vgt-wrap__footer .footer__navigation__page-info__current-entry {
    width: 50px !important;
    text-align: center;
    vertical-align: middle;
    display: inline-block;
    margin: 0px 10px;
    font-weight: bold;
}
.Fkit-btn {
    background: #000;
    margin-left: 0px;
    margin-bottom: 15px;
}

.label-font {
    font-size: .9rem;
    margin-right: 0px;
}


.shop_bag {
    transition: transform 0.3s ease;
}

.shop_bag.active {
    animation: wishlist-animation 0.5s ease;
    color: red; /* Optional: Change color when active */
    
}

@keyframes wishlist-animation {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.5);
    }
    100% {
        transform: scale(1);
    }
}
.w-list{
  color: red;
}
.paddingrow {
    padding-left: 12px;
    padding-right: 12px;
}


</style>
