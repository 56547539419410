const user = localStorage.getItem("nrsllrcust");
const initialState = user ? true : false;
let initialUserData = {};
let initialUserLocation = { lat: "8.5241", lng: "76.9366" };
let initialLocationTxt = "";
let initialBusinessId = false;
let initialServicePrvdId = false;
let initialBusSearchParams = {
  sort_by: "",
  rating_filter: "",
  radial_dist: "",
};
let initialProdSearchParams = {
  sort_by: "",
  rating_filter: "",
  radial_dist: "",
};
if (user) {
  initialUserData = JSON.parse(user);
  initialBusinessId = initialUserData.def_bus_id;
  initialServicePrvdId = initialUserData.def_prvd_id
}
export default {
  namespaced: true,
  state: {
    userLoggedin: initialState,
    userData: initialUserData,
    userLocation: initialUserLocation,
    userLocationTxt: initialLocationTxt,
    defaultBusinessId: initialBusinessId,
    defaultservicePrvdId: initialServicePrvdId,
    searchBusParams: initialBusSearchParams,
    searchProdParams: initialProdSearchParams,
  },
  getters: {},
  mutations: {
    logOutUser: (state) => {
      state.userLoggedin = initialState;
    },
    logInUser: (state) => {
      state.userLoggedin = true;
    },
    changeUserData: (state, payload) => {
      state.userData = payload;
      // localStorage.setItem('nrsllrcust', JSON.stringify(payload));
    },
    changeUserLocation: (state, payload) => {
      state.userLocation = payload;
    },
    changeLocationTxt: (state, payload) => {
      state.userLocationTxt = payload;
    },
    changeBusinessId: (state, payload) => {
      state.defaultBusinessId = payload;
    },
    changeServiceProvidId:(state, payload)=>{
      state.defaultservicePrvdId = payload
    },
    changeBusinessSearchParam: (state, payload) => {
      state.searchBusParams = payload;
    },
    changeProductSearchParam: (state, payload) => {
      state.searchProdParams = payload;
    },
    updateUserBusinessId: (state, { userId, businessId }) => {
      if (state.userData.id == userId) {
        state.userData.def_bus_id = businessId;
        state.defaultBusinessId = businessId; 
        localStorage.setItem('nrsllrcust', JSON.stringify(state.userData));
      }
    },
  },
  actions: {
    logOutUser: (context) => {
      context.commit("logOutUser");
    },
    logInUser: (context) => {
      context.commit("logInUser");
    },
    changeUserData: (context, payload) => {
      context.commit("changeUserData", payload);
    },
    changeUserLocation: (context, payload) => {
      context.commit("changeUserLocation", payload);
    },
    changeLocationTxt: (context, payload) => {
      context.commit("changeLocationTxt", payload);
    },
    changeBusinessId: (context, payload) => {
      context.commit("changeBusinessId", payload);
    },
    changeServiceProvidId: (context, payload) => {
      context.commit("changeServiceProvidId", payload);
    },
    changeBusinessSearchParam: (context, payload) => {
      context.commit("changeBusinessSearchParam", payload);
    },
    changeProductSearchParam: (context, payload) => {
      context.commit("changeProductSearchParam", payload);
    },
    async updateUserBusinessId({ commit }, { userId, businessId }) {
      commit("updateUserBusinessId", { userId, businessId });
    }
  },
};
